import Vue from "vue";
import VueRouter from "vue-router";
import RouterSite from "./site";
import RouterAluno from "./aluno";
import { gtag, GA_TRACKING_ID } from "./../utils/gtag";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/aluno/login"
  },
  RouterSite,
  RouterAluno,
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/erros/401.vue")
  },
  {
    path: "/redirect-auth",
    name: "redirect-auth",
    component: () => import("../views/authRedirect.vue")
  },
  {
    path: "/esqueci-minha-senha",
    name: "esqueciMinhaSenha",
    component: () => import("@/views/esqueci-minha-senha/index.vue")
  },
  {
    path: "/esqueci-minha-senha/:email/:codigo",
    name: "esqueciMinhaSenha",
    component: () => import("@/views/esqueci-minha-senha/recuperando.vue"),
    props: true
  },
  {
    path: "*",
    component: () => import("@/views/erros/404")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path) {
    gtag("config", GA_TRACKING_ID, {
      page_path: to.path
    });
  }
  next();
});

export default router;
