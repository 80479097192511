import axios from "axios";
import store from "@/store/index.js";

function createTurma(payload) {
  return {
    ano: payload.ano ? payload.ano : null,
    cidadeId: payload.cidadeId ? payload.cidadeId : null,
    cnpj: payload.cnpj ? payload.cnpj : null,
    codigoConvite: payload.codigoConvite ? payload.codigoConvite : null,
    cursos: payload.cursos ? payload.cursos : null,
    dataCadastro: payload.dataCadastro ? payload.dataCadastro : null,
    dataFormatura: payload.dataFormatura ? payload.dataFormatura : null,
    diasRestanteTeste: payload.diasRestanteTeste
      ? payload.diasRestanteTeste
      : null,
    enderecoCompleto: payload.enderecoCompleto
      ? payload.enderecoCompleto
      : null,
    estadoId: payload.estadoId ? payload.estadoId : null,
    id: payload.id ? payload.id : null,
    instituicoes: payload.instituicoes ? payload.instituicoes : null,
    nome: payload.nome ? payload.nome : null,
    nomeNoiva: payload.nomeNoiva ? payload.nomeNoiva : null,
    nomeNoivo: payload.nomeNoivo ? payload.nomeNoivo : null,
    numeroFormando: payload.numeroFormando ? payload.numeroFormando : null,
    pacoteId: payload.pacoteId ? payload.pacoteId : null,
    pacoteTitulo: payload.pacoteTitulo ? payload.pacoteTitulo : null,
    periodo: payload.periodo ? payload.periodo : null,
    semestre: payload.semestre ? payload.semestre : null,
    tipoEvento: payload.tipoEvento ? payload.tipoEvento : null,
    tipoEventoDescricao: payload.tipoEventoDescricao
      ? payload.tipoEventoDescricao
      : null,
    usuarioCriouTurmaId: payload.usuarioCriouTurmaId
      ? payload.usuarioCriouTurmaId
      : null
  };
}

export default {
  cancelarContrato(participanteId) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post(`/v1/turma/cancelar-contrato-aluno`, {
      turmaId: turmaID,
      usuarioId: participanteId
    });
  },
  buscarTurma(nomeTurma) {
    return axios.post("/v1/turma/search", {
      texto: nomeTurma
    });
  },
  loadDashboard(ano) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post(`/v1/turma/${turmaID}/dashboard`, { ano: ano });
  },
  getMetaAlunos() {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.get(`/v1/turma/${turmaID}/alunos-metas`);
  },
  updateMetaAluno(payload) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post(`/v1/turma/${turmaID}/update-meta-aluno`, {
      novoValor: payload.novoValor,
      usuarioId: payload.usuarioId
    });
  },
  finalizaPlano(turmaID) {
    return axios.post(`/v1/turma/${turmaID}/finalizar-plano`);
  },
  setPlanoTurma(turmaId, payload) {
    return axios.post(`/v1/turma/${turmaId}/plano`, payload);
  },
  getPlanoTurma(turmaId) {
    return axios.get(`/v1/turma/${turmaId}/plano`);
  },
  addAlunoTurma(turmaID, payload) {
    return axios.post(`/v1/turma/${turmaID}/add-aluno`, {
      email: payload.email,
      nome: payload.nome,
      meta: payload.meta
    });
  },
  reenviarConvite(email) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post("/v1/turma/reenviar-convite", {
      turmaId: turmaID,
      email: email
    });
  },
  excluirParticipante(userID) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.delete(`/v1/turma/excluir-convite`, {
      data: {
        turmaId: turmaID,
        usuarioId: userID
      }
    });
  },
  desativaAluno(turmaID, usuarioID) {
    return axios.post("/v1/turma/bloquear-aluno", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  aceitaAluno(turmaID, usuarioID) {
    return axios.post("/v1/turma/permitir-aluno", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  nomearAlunoAdmin(turmaID, usuarioID) {
    return axios.post("/v1/turma/nomear-aluno-admin", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  removerAlunoComissao(turmaID, usuarioID) {
    return axios.post("/v1/turma/remover-aluno-comissao", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  addAlunoComissao(turmaID, usuarioID) {
    return axios.post("/v1/turma/add-aluno-comissao", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  novoEvento(payload) {
    let requestBody = createTurma(payload);
    return axios.post("/v1/turma", requestBody);
  },
  alterarEvento(payload) {
    let requestBody = createTurma(payload);
    return axios.post(`/v1/turma/${payload.id}`, requestBody);
  },
  getTurma(turmaId) {
    return axios.get(`/v1/turma/${turmaId}`);
  },
  updateTurma(turmaId, payload) {
    return axios.post(`/v1/turma/${turmaId}`, payload);
  },
  getAlunos(turmaId) {
    return axios.get(`/v1/turma/${turmaId}/alunos`);
  }
};
